import React from 'react'
import Header from './componets/Header'
import Footer from './componets/Footer'
import Home from './pages/Home'

const App = () => {
  return (
    <>
      <div className='Main-Entery'>
        <Header />
        <Home />
        <Footer />
      </div>
    </>
  )
}

export default App