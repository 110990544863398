import React, { useState, useEffect } from "react";
import img1 from "../assets/img1.png";
import "./header.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const menus = [
    {
      id: "1",
      title: "Menu1",
      link: "#",
    },
    { id: "2", title: "Menu2", link: "#" },
    { id: "3", title: "Menu3", link: "#" },
    { id: "4", title: "Menu4", link: "#" },
    { id: "5", title: "Menu5", link: "#" },
  ];

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    // Set the header to show only after scrolling past Section 1
    if (currentScrollY > window.innerHeight) {
      if (currentScrollY > lastScrollY) {
        setShowHeader(false); // Hide on scroll down
      } else {
        setShowHeader(true); // Show on scroll up
      }
    } else {
      setShowHeader(false); // Hide in Section 1
    }
    setLastScrollY(currentScrollY);
  };

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
      setIsMenuOpen(false); // Close the menu if not in mobile view
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [lastScrollY]);

  return (
    <>
      <nav
        style={{ height: "11.5vh", width: "100%" }}
        className={`navbar navbar-expand-lg fixed-top header-transition ${
          showHeader ? "header-visible" : "header-hidden"
        }`}
      >
        <div className="container-fluid px-4  d-flex align-items-center">
          <img
            style={{ zIndex: 1000 }}
            className="logo"
            src={img1}
            alt="Logo"
          />

          {/* {!isMobile && (
            <ul
              className="navbar-nav fs-3 fw-bold d-flex justify-content-center align-items-center mx-auto mb-2 mb-lg-0"
              style={{ width: "100%" }}
            >
            
              <div className="d-flex gap-5">
                {menus.map((item) => (
                  <li className="nav-item" key={item.id}>
                    <a
                      className="nav-link"
                      href={item.link}
                      style={{
                        background:
                          "linear-gradient(to right, #FF5E43, #FB992E)",
                        WebkitBackgroundClip: "text",
                        color: "transparent",
                      }}
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </div>
            </ul>
          )} */}

          {/* Get Quote Button */}
          {!isMobile && (
            <button
              className="d-flex justify-content-center align-items-center text-white fw-bold fs-5 border py-2 rounded-pill px-4 ms-5"
              style={{
                background: "linear-gradient(to right, #FF5E43,#FB992E)",
                whiteSpace: "nowrap",
              }}
            >
              Get Quote
            </button>
          )}

          {/* Mobile Menu Icon */}
          {isMobile && (
            <div
              onClick={toggleMenu}
              className="d-flex gap-5 justify-content-center align-items-center  "
              style={{ zIndex: 1000 }}
            >
              <button
                className=" btn text-white fw-bold border-0   py-2 rounded-pill px-4 "
                style={{
                  background: "linear-gradient(to right, #FF5E43,#FB992E)",
                }}
              >
                Get Quote
              </button>

              {isMenuOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  width="40"
                  height="40"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="currentColor"
                  className="bi bi-list"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                  />
                </svg>
              )}
            </div>
          )}
        </div>

        {/* Fullscreen Menu */}
        <div className={`fullscreen-menu ${isMenuOpen ? "open" : ""}`}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <ul className="navbar-nav fs-1 fw-bold gap-2 mx-auto">
              {menus.map((item) => (
                <li className="nav-item" key={item.id}>
                  <a
                    className="nav-link"
                    href={item.link}
                    style={{
                      background: "linear-gradient(to right, #FF5E43, #FB992E)",
                      WebkitBackgroundClip: "text",
                      color: "transparent",
                    }}
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>

            <button
              className="mt-3 btn-menu   text-white fw-bold border-0   py-2 rounded-pill px-4 "
              style={{
                background: "linear-gradient(to right, #FF5E43,#FB992E)",
              }}
            >
              Get Quote
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
